<template>
  <div
    class="game-item  cursor-pointer relative overflow-hidden rounded-[10%]"
    :class="{
      'is-not-search': !isSearch,
    }"
    @click="startGame"
  >
    <div
      class="game-favorite-icon absolute text-28px right-[5%] top-[5%] z-10"
      @click.stop.prevent="isFavoriteLoading ? undefined : favoriteGame()"
    >
      <CommonAsset
        class="w-0.8em"
        :name="
          item.isFavorite ? 'icons-favorite-clicked' : 'icons-favorite-unclick'
        "
        client
      />
    </div>
    <CommonAsset
      :src="item?.latestIcon ?? item?.originalIcon"
      :alt="`img-${item?.name ?? item?.id}`"
      class="w-full h-full object-top object-cover"
      client
    />
    <div
      class="game-shadow absolute text-28px w-full h-full inset-0 flex justify-center items-center !<sm:opacity-100 !<sm:bg-[unset,transparent] !<sm:blur-0"
    >
      <div
        class="play-icon absolute top-[42%] flex justify-center w-30px !<sm:opacity-0 <xl:w-25px <lg:w-20px <lg:top-[44%]"
      >
        <CommonAsset name="icons-play-icon" class="w-full" client />
      </div>
      <div
        class="text-center !<sm:opacity-0 show-game-name absolute w-full bottom-20px text-center left-0"
      >
        <p
          class="font-800 text-[0.65em] mt-40px show-2 px-[0.25em] <xl:text-[0.45em]"
        >
          {{ item?.name }}
        </p>
        <p class="text-[0.4em] mt-5px break-words whitespace-nowrap">
          {{ item?.gameProviderName }}
        </p>
      </div>
    </div>
    <div
      v-if="item.status === 2"
      class="maintenance-item absolute w-full h-full flex justify-center items-center flex-col"
    >
      <div class="flex justify-center">
        <CommonAsset
          name="images-common-no-data"
          class="w-[65%] <xl:w-[50%]"
        />
      </div>
      <p
        class="font-800 text-center text-1em mt-2em px-1em <xl:text-0.8em <xl:mt-1.5em <lg:text-0.8em <md:text-1.2em <sm:text-1.5em"
      >
        Working on some updates.We will be back at
        {{ getMaintainTime(item?.maintenance) }}
      </p>
    </div>
    
  </div>
</template>

<script setup lang="ts">
import { useUnLogin } from "~/composables/unLogin"
import { useGame } from "~/stores/game"
import { getMaintainTime } from "~/utils/date-filter"
const { handleUnLogin } = useUnLogin()
const game = useGame()
const router = useRouter()
const route = useRoute()
const emit = defineEmits(["change","dialog-flag"])
const isFavoriteLoading = ref<boolean>(false)
const props = withDefaults(
  defineProps<{
    item: Partial<SpaceGameRelated.SlotGameList.GameItem>
    isSearch?: Boolean
  }>(),
  {
    isSearch: () => false,
    item: () => ({}),
  }
)
// 收藏游戏
const favoriteGame = async () => {
  try {
    isFavoriteLoading.value = true
    if (await handleUnLogin()) return

    const { item } = props
    const params = {
      direction: item.isFavorite ? 0 : 1,
      gameProviderSubtypeId: Number(item.gameProviderSubtypeId),
      gameSlotId: item.id,
    }
    await game.GAME_COLLECTION(params)
    item.isFavorite = item.isFavorite ? 0 : 1
    useNotify({
      title: "Successfully",
      message: params.direction
        ? "added to favorites"
        : "removed from favorites",
      type: "success",
      position: "top-left",
    })
  } catch (e) {
    useNotify({
      title: "Failed",
      message: "Collection Failed",
      type: "error",
      position: "top-left",
    })
  } finally {
    isFavoriteLoading.value = false
  }
}
const startGame = async () => {
  try {
    if (props.isSearch) emit("change")
    if (await handleUnLogin()) return
    const {
      item: { status, id: gameId, gameProviderSubtypeId },
    } = props
    if (props.item.gameProviderId == 36) {
      emit("dialog-flag", true, props.item)
    } else if (status === 1) {
      sessionStorage.setItem("_gamefrom", route.name)
      router.push({
        name: "GameRoom",
        query: { gameId, gameProviderSubtypeId },
      })
    }
  } catch (e) {}
}
</script>

<style lang="scss" scoped>
.game-item {
  background-color: var(--v-navbar-bg);
  transition: all 0.3s;
  aspect-ratio: 166/222;
  .play-icon {
    transform: translateY(-50%);
  }
  &.is-not-search {
    @apply flex-shrink-0;
  }

  .game-favorite-icon {
    @apply opacity-0 <md:opacity-100;
  }
  &:hover {
    transform: translateY(-10px);
    background-size: auto 103%;
    .game-shadow {
      opacity: 1;
    }
    .game-favorite-icon {
      opacity: 1;
    }
  }

  .game-shadow {
    background: linear-gradient(180deg, #b2ff4d 0%, #6cb515 100%);
    backdrop-filter: blur(1px);
    opacity: 0;

    @media screen and (max-width: 640px) {
      background: transparent;
    }
  }

  .show-game-name {
    color: var(--v-game-item-name);
    word-break: break-word;
  }
  .maintenance-item {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 500px) {
  .maintenance-item {
    img {
      width: 50px;
    }
    p {
      font-size: 10px;
    }
  }
}
@media (max-width: 400px) {
  .maintenance-item {
    img {
      width: 40px;
    }
    p {
      font-size: 8px;
    }
  }
}
</style>
