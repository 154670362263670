<template>
  <section
    class="mt-8px rounded-40px items-center <sm:h-32px @sm:h-34px @md:h-36px @lg:h-38px p-4px home-announcement h-40px flex"
  >
    <span
      class="flex items-center py-7px <sm:px-10px <sm:py-5px px-19px rounded-20px announcement-icon"
    >
      <CommonAsset name="icons-announcement" class="w-16px <sm:w-12px" />
    </span>
    <div
      class="flex-1 flex items-center ml-8px <sm:ml-5px relative h-full overflow-hidden"
    >
      <Vue3Marquee :duration="duration">
        <div v-for="(item, index) in noticeList" :key="index">
          <span class="mr-10px font-900">{{ item?.title }}</span>
          <span>{{ item?.content }}&nbsp; &nbsp; &nbsp;</span>
        </div>
      </Vue3Marquee>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Vue3Marquee } from "vue3-marquee"
import "vue3-marquee/dist/style.css"
import { useMessage } from "~/stores/message"
// Stores（狀態處理器）
const messageStore = useMessage()
// Computed（附依賴變量）
const noticeList = computed(() => messageStore.noticeList)
const duration = computed(
  () =>
    noticeList.value.reduce((pre, cur) => pre + (cur.title + cur.content), "")
      .length / 4
)
try {
  await messageStore.GET_NOTICE()
} catch (error) {
  console.log(error)
}
</script>

<style lang="scss" scoped>
.home-announcement {
  background: var(--v-announcement-bg);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  .announcement-icon {
    background: var(--v-announcement-icon-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    .announcement-text {
      color: var(--v-announcement-text-bg);
    }
  }
  :deep(.vue3-marquee > .marquee) {
    min-width: min-content;
  }
}
.move-announce {
  animation: move 1s linear infinite;
  animation-play-state: running;
  cursor: pointer;
}
</style>
